import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import { Body } from '../styles';

const NotFoundPage = () => (
	<Layout>
		<Body center>
			The page that you requested was not found. Please click here to <Link to="/">return home.</Link>
		</Body>
	</Layout>
);

export default NotFoundPage;
